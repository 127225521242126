import React, { InputHTMLAttributes, forwardRef, ReactNode } from 'react'
import cx from 'classnames'

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'required'> {
  label?: string
  size?: 'default' | 'big' | 'small'
  isRequired?: boolean
  extra?: ReactNode
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, label, size = 'default', isRequired, extra, ...props },
    ref
  ) => {
    return (
      <div className="flex flex-col flex-1">
        {(label || extra) && (
          <div className="flex items-center mb-3">
            {label && <label className="font-medium text-sm">{label}</label>}
            {extra && <div className="ml-auto">{extra}</div>}
          </div>
        )}
        <input
          ref={ref}
          required={isRequired}
          className={cx(
            'border rounded w-full placeholder-gray-400 focus:outline-none focus:border-primary',
            {
              'h-12 px-3': size === 'big',
              'h-10 px-3': size === 'default',
              'h-8 px-2': size === 'small',
            },
            className
          )}
          {...props}
        />
      </div>
    )
  }
)

export default Input
